<template>
  <v-card flat class="mb-2 pa-2  lighten-5">
    <v-card-text>
      <v-row>
        <v-col xs="12">
          <v-progress-linear
            indeterminate
            color="primary"
            v-if="processing"
          ></v-progress-linear>
          <v-subheader style="font-size: 16px !important">Prepago</v-subheader>
          <v-autocomplete
            label="Tipo producto"
            v-model="tipoProductoSelected"
            :items="tipoProductoOptions"
            item-text="label"
            item-value="value"
            prepend-inner-icon="scatter_plot"
            append-icon="keyboard_arrow_down"
            class="pa-3"
          ></v-autocomplete>

          <div ref="amchart" style="height: 45vh"></div>
          <div class="ml-4 mr-4 pa-4">{{ getIndicadorDescription() }}</div>
          <v-alert
            dense
            type="warning"
            text
            v-show="!processing && isChartEmpty"
            >No existe información para esta gráfica</v-alert
          >
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import apiDashboard from "@/api/dashboards";
import { mapActions } from "vuex";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themesAnimated from "@amcharts/amcharts4/themes/material";
import { store } from "@/store";
import moment from "moment";

am4core.useTheme(am4themesAnimated);

export default {
  props: ["fechaFin"],
  data() {
    return {
      processing: false,
      chartData: [],
      chart: null,
      tipoProductoSelected: null,
      tipoProductoOptions: [
        {
          label: "Antigüedad Bajas 6 Meses",
          value: "tasa_antiguedad",
          description:
            "Porcentaje de las líneas que fueron dadas de bajas en el mes en cuestión, con una antigüedad menor de 6 meses.",
        },
        {
          label: "Churn Anual",
          value: "tasa_churn",
          description:
            "Es una tasa de churn calculada SOLO tomando en cuenta las líneas activadas en los 12 meses previos al mes en el cual se está haciendo el cálculo.",
        },
        {
          label: "ChargeBack 30 días",
          value: "tasa_chargeback",
          description:
            "El cociente entre todas las líneas a las cuales se le aplicó Charge Back 30, en el mes en cuestión, entre el total de líneas que cumplieron 30 días de activadas en dicho mes.",
        },
        {
          label: "No Recarga 60 días",
          value: "tasa_norecarga",
          description:
            "Porcentaje de las líneas que cumplieron 60 días de activadas en el mes en cuestión, y no ha realizado ninguna recarga al finalizar dicho mes.",
        },
      ],
    };
  },
  computed: {
    monedaSistema() {
      return store.getters.monedaSistema;
    },
    isChartEmpty() {
      return this.chartData.length === 0;
    },
  },
  methods: {
    ...mapActions(["setError", "setErrorMessage", "setMessage"]),
    generateChart(dataParam) {
      if (this.chart) {
        this.chart.dispose();
      }

      var _indicador = this.tipoProductoSelected;

      try {
        let chart = am4core.create(this.$refs.amchart, am4charts.XYChart);
        chart.colors.step = 2;
        chart.legend = new am4charts.Legend();
        chart.exporting.menu = new am4core.ExportMenu();
        chart.numberFormatter.numberFormat = "$#,###.##";

        let data = [];
        var periodo = [];
        dataParam.forEach(function (row) {
          if (periodo.indexOf(row.Fecha) === -1) {
            periodo.push(moment(row.Fecha).format("YYYY-MM"));
          }
        });

        periodo.forEach(function (d) {
          let rows = dataParam.filter(
            (f) => moment(f.Fecha).format("YYYY-MM") === d
          );
          let newRow = {
            Periodo: d,
            Canal: 0,
            CAC: 0,
            Asociado: 0,
          };
          let tasaAsociado = 0;
          let tasaCanal = 0;
          let tasaCAC = 0;

          rows.forEach(function (c) {
            if (c) {
              if (c.CodAsociado === "DAC") {
                switch (_indicador) {
                  case "tasa_antiguedad":
                    tasaCanal = c.TasaAntiguedad;
                    break;
                  case "tasa_churn":
                    tasaCanal = c.TasaChurn;
                    break;
                  case "tasa_chargeback":
                    tasaCanal = c.TasaCargeback;
                    break;
                  case "tasa_norecarga":
                    tasaCanal = c.TasaNoRecarga;
                    break;
                  default:
                    tasaCanal = c.TasaAntiguedad;
                }
              } else if (c.CodAsociado === "CAC") {
                switch (_indicador) {
                  case "tasa_antiguedad":
                    tasaCAC = c.TasaAntiguedad;
                    break;
                  case "tasa_churn":
                    tasaCAC = c.TasaChurn;
                    break;
                  case "tasa_chargeback":
                    tasaCAC = c.TasaCargeback;
                    break;
                  case "tasa_norecarga":
                    tasaCAC = c.TasaNoRecarga;
                    break;
                  default:
                    tasaCAC = c.TasaAntiguedad;
                }
              } else {
                switch (_indicador) {
                  case "tasa_antiguedad":
                    tasaAsociado = c.TasaAntiguedad;
                    break;
                  case "tasa_churn":
                    tasaAsociado = c.TasaChurn;
                    break;
                  case "tasa_chargeback":
                    tasaAsociado = c.TasaCargeback;
                    break;
                  case "tasa_norecarga":
                    tasaAsociado = c.TasaNoRecarga;
                    break;
                  default:
                    tasaAsociado = c.TasaAntiguedad;
                }
              }
            }
          });
          newRow["Canal"] = (tasaCanal * 100).toFixed(2);
          newRow["CAC"] = (tasaCAC * 100).toFixed(2);
          newRow["Asociado"] = (tasaAsociado * 100).toFixed(2);
          data.push(newRow);
        });

        chart.data = data;

        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "Periodo";
        categoryAxis.title.text = "Periodo";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 20;
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.minWidth = 35;
        valueAxis.renderer.labels.template.adapter.add("text", function (text) {
          return text + "%";
        });

        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.categoryX = "Periodo";
        series.dataFields.valueY = "Canal";
        series.name = store.getters.user.canal;
        series.stacked = true;
        series.columns.template.width = am4core.percent(80);
        series.columns.template.fill = am4core.color("#008080");
        series.columns.template.stroke = am4core.color("#008080");
        series.columns.template.tooltipText =
          "{name}: [bold]" + this.monedaSistema + "{valueY}%[/]";
        series.stacked = true;

        let lineSeries = chart.series.push(new am4charts.LineSeries());
        lineSeries.dataFields.categoryX = "Periodo";
        lineSeries.dataFields.valueY = "Asociado";
        lineSeries.name = store.getters.user.nombre;
        lineSeries.strokeWidth = 3;
        var circleBullet = lineSeries.bullets.push(
          new am4charts.CircleBullet()
        );
        circleBullet.circle.stroke = am4core.color("#fff");
        circleBullet.tooltipText = "{name}: [bold]{Asociado}%[/]";

        this.chart = chart;
      } catch (e) {
        // empty
      }
    },
    refresh() {
      if (this.fechaFin) {
        this.processing = true;
        let param = {
          FechaFin: this.fechaFin,
          CodigoAsociado: store.getters.user.asociado.codigo,
          Chart: "Prepago",
        };

        apiDashboard
          .getChartCalidadVenta(param)
          .then((response) => {
            this.chartData = response.data;
            this.generateChart(this.chartData);
            this.processing = false;
          })
          .catch((error) => {
            this.setError(error);
            this.processing = false;
          });
      }
    },
    getIndicadorDescription() {
      if (this.tipoProductoSelected) {
        var item = this.tipoProductoOptions.filter((i) => {
          return i.value === this.tipoProductoSelected;
        });

        return item[0].description;
      }
    },
  },
  watch: {
    fechaFin: function () {
      this.refresh();
    },
    tipoProductoSelected: function () {
      this.generateChart(this.chartData);
    },
  },
  mounted() {
    this.tipoProductoSelected = this.tipoProductoOptions[0].value;
    this.refresh();
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
};
</script>

<style scope>
.v-alert.v-sheet.theme--dark.v-alert--dense.warning {
  margin-bottom: 0;
}
</style>